@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-light;
    @apply h-[100vh];
    @apply bg-[#efeeed];
    @apply text-gray-700;
  }
}

@layer components {
  .pagetitle {
    @apply text-2xl border-b-4 border-[#979795] mx-auto max-w-[110px] my-4 pt-8;
  }
  .content-title {
    @apply text-2xl block border-b-4 border-[#979795] text-center mx-auto max-w-[300px];
  }
  .skillbox {
    @apply shadow-md shadow-[#c3bdb3] hover:scale-110 duration-500;
  }
  #social-links li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

/* HEADER */

header {
  background-color: #f5f4f3;
}

#mobile-menu {
  background-color: #f5f4f3;
}

#color-mode {
  background-color: #f5f4f3;
}
nav.menu-div li {
  font-weight: 400;
  padding-right: 30px;
  cursor: pointer;
  transition: all 0.5s;
}
nav.menu-div li:hover {
  transition: all 0.5s;
  text-shadow: 1px 1px 20px #9b7805;
}

.dark nav.menu-div li:hover {
  transition: all 0.5s;
  text-shadow: 1px 1px 20px white;
}

/* HOME PAGE */

.content-div {
  background-repeat: none;
  background-position: 50%;
  background-size: cover;
  height: 240px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(227, 227, 213, 0.836),
    hsla(58, 20%, 71%, 0.8)
  ) !important;
}

input:focus,
textarea:focus {
  border: 2px solid #6a6a68;
  outline: none;
}

input,
textarea {
  border: 2px solid #efeeed;
  outline: none;
}

.work-cover-text {
  font-size: 1.25em;
  line-height: 1em;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: rgb(255, 255, 255);
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
}

/* Inner Pages */

.content-page {
  min-width: 300px;
  color: rgb(55 65 81);
  width: 100%;
  padding-top: 6em;
  padding-bottom: 4em;
  padding-left: 4em;
  padding-right: 4em;
}

.project-meta {
  text-align: right;
  padding-top: 1em;
}

ul.tech-ul li {
  padding-bottom: 10px;
}

ul.tech-ul b {
  font-weight: 700;
}

.image-hero {
  border: 2px gray solid;
  margin: 20px auto 20px auto;
  width: 80%;
  height: auto;
  display: block;
}

/* DARK MODE */

.dark {
  background-color: #1b1b1b;
  color: #d1cbc0;
}

.dark #mobile-menu,
.dark #header {
  background-color: #141414;
  border-bottom: 2px solid #d1cbc0;
}

.dark #logo {
  filter: sepia(80%) brightness(280%);
  cursor: pointer;
}

.dark #color-mode {
  background-color: #141414;
}

.dark .content-page {
  color: #d1cbc0;
}

.dark .skillbox {
  box-shadow: 0 2px #d1cbc0;
}
.dark .content-div:hover {
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 247, 0.9),
    hsla(57, 40%, 90%, 0.5)
  ) !important;
}

section.page {
  height: 100vh;
  min-height: 700px;
  padding-top: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 752px) {
  section.large-page {
    height: 110vh;
    min-height: 1200px;
  }

  section.extra-large-page {
    height: 125vh;
    min-height: 1450px;
  }
}

.work-tile {
  margin: 0.5em 0;
  padding: 0.5em 0.5em;
  color: #374151;
  background-color: #efeeed;
}

.work-tile .job-title {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .work-tile .job-title {
    display: block;
    text-align: left;
  }
}

.work-tile h3 {
  font-size: 1.25em;
  font-weight: 400;
}

.work-tile p.tech {
  margin-right: 8px;
  margin-top: 4px;
  padding: 5px 10px;
  border-radius: 20px;
  word-wrap: none;
  font-size: 0.85em;
  font-weight: 400;
  background-color: #bebeba;
  color: #374151;
  cursor: default;
}

.work-tile p.tech:hover {
  background-color: #dcdcd6;
}

.work-tile .divider {
  padding-top: 10px;
  border-bottom: 1px #bebeba solid;
}

.project-button {
  background-color: #efeeed;
  color: #374151;
  font-size: 1em;
  font-weight: 800;
  border-radius: 10px;
  padding: 0.65em 0.75em;
  margin: 0 0.25em;
  letter-spacing: 1px;
}

.project-button:hover {
  background-color: black;
  color: white;
}

.dark .work-tile {
  color: #d1cbc0;
  background-color: #1b1b1b;
}

.dark .work-tile h3,
.dark .work-tile b,
.dark .work-tile i {
  color: #ddd;
  background-color: #1b1b1b;
}

.contact-links a {
  color: #374151;
}

.dark .contact-links a {
  color: #bebeba;
}

.contact-links a:hover {
  color: #847353;
}

.dark .contact-links a:hover {
  color: #ffffff;
}
